<template>
    <div>
        <b-container class="pt-5 pb-5">
            <b-card no-body>
                <b-card-body>
                    <h1 class="fs-4 font-weight-bold mb-3">Cadeaukaartsaldo</h1>
                    <b-alert variant="info" show>Geen tegoed beschikbaar.</b-alert>
                </b-card-body>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    export default ({
        components: {},
        metaInfo: {
            title: 'Cadeaukaartsaldo',
        },
        data() {
            return {
                tickets: [],
            }
        },
        methods: {
            
        },
        created() {
     
        }
    });
</script>